export const localeDateFormatMap = {
  en: 'MM/DD/YYYY',
  'aluguel-de-motos': 'DD/MM/YYYY',
  'noleggio-moto': 'DD/MM/YYYY',
  'alquiler-de-motos': 'DD/MM/YYYY',
  'location-moto': 'DD/MM/YYYY',
  'motorrad-mieten': 'DD.MM.YYYY',
};

export const localeDateMaskMap = {
  en: '__/__/____',
  'aluguel-de-motos': '__/__/____',
  'noleggio-moto': '__/__/____',
  'alquiler-de-motos': '__/__/____',
  'location-moto': '__/__/____',
  'motorrad-mieten': '__.__.____',
};
