import React, { useEffect, useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
// Material UI core
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const PREFIX = 'Breadcrumbs';

const classes = {
  root: `${PREFIX}-root`,
  bcLinksCon: `${PREFIX}-bcLinksCon`,
  bcNav: `${PREFIX}-bcNav`,
  bcArrow: `${PREFIX}-bcArrow`,
  bcListItem: `${PREFIX}-bcListItem`,
  bcLink: `${PREFIX}-bcLink`,
  bcSeparator: `${PREFIX}-bcSeparator`,
  bcText: `${PREFIX}-bcText`,
};

const StyledBox = styled(Box)(
  ({ theme, iconBackgroundColor, showBcArrow }) => ({
    [`&.${classes.root}`]: {
      lineHeight: theme.typography.pxToRem(13),
      fontSize: theme.typography.pxToRem(12),
      fontWeight: '500',
      textTransform: 'uppercase',
      overflow: 'hidden',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        position: 'relative',
        overflowX: 'hidden',
      },
    },

    [`& .${classes.bcLinksCon}`]: {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        transition: 'all .6s',
        paddingLeft: showBcArrow ? theme.typography.pxToRem(55) : 0,
      },
    },

    [`& .${classes.bcNav}`]: {
      padding: `0 ${theme.typography.pxToRem(15)}`,
      position: 'absolute',
      width: `${theme.typography.pxToRem(47)}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      left: '0rem',
      top: 0,
      bottom: 0,
      margin: 'auto',
      boxSizing: 'borderBox',
      zIndex: '2',
      backgroundColor: iconBackgroundColor || theme.palette.common.white,
      '&::after': {
        content: "''",
        position: 'absolute',
        height: '70%',
        width: '1px',
        left: theme.typography.pxToRem(47),
        backgroundColor: theme.palette.grey[50],
        top: 0,
        bottom: 0,
        margin: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },

    [`& .${classes.bcArrow}`]: {
      color: theme.palette.primary.main,
      '&.rotated': {
        transform: 'rotate(180deg)',
        transition: 'all 0.6s',
      },
    },

    [`& .${classes.bcListItem}`]: {
      paddingRight: theme.typography.pxToRem(12),
      paddingLeft: 0,
      [theme.breakpoints.up('lg')]: {
        display: 'inline',
      },
    },

    [`& .${classes.bcLink}`]: {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: 700,
    },

    [`& .${classes.bcSeparator}`]: {
      color: theme.palette.grey[200],
      marginLeft: theme.typography.pxToRem(12),
    },

    [`& .${classes.bcText}`]: {
      color: theme.palette.grey[200],
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(12),
    },
    '& .BCLabel': {
      color: theme.palette.secondary.main,
    },
  }),
);

const Breadcrumbs = (props) => {
  const { data, iconBackgroundColor } = props;
  const theme = useTheme();
  const isLargeDown = useMediaQuery(theme.breakpoints.down('xl'));
  const [dir, setDir] = useState('right');
  const [showBcArrow, setShowBcArrow] = useState(
    data ? data.links?.length > 2 : true,
  );
  const breadcrumbsWrapper = useRef(null);
  const breadcrumbs = useRef(null);
  const breadcrumbsArrow = useRef(null);
  const GetBcScrollValue = () => {
    const outerWidth = breadcrumbsWrapper.current.offsetWidth;
    const scrollWidthElm = breadcrumbs.current.scrollWidth;
    const scrollingValue = scrollWidthElm - outerWidth;
    if (scrollingValue <= 0) {
      setShowBcArrow(false);
    } else {
      setShowBcArrow(true);
      breadcrumbs.current.style.paddingLeft = theme.typography.pxToRem(55);
    }
    return scrollingValue;
  };
  const handleBcArrow = () => {
    // eslint-disable-next-line no-unused-expressions
    dir == 'right' ? setDir('left') : dir == 'left' && setDir('right');
    const scrollingValue = GetBcScrollValue();
    breadcrumbsArrow.current.classList.toggle('rotated');
    if (dir == 'right') {
      breadcrumbs.current.style.marginLeft = `${-scrollingValue}px`;
    }
    if (dir == 'left') {
      breadcrumbs.current.style.marginLeft = `${0}px`;
    }
  };

  const scrollToRightOnMobile = () => {
    isLargeDown && setDir('left');
    handleBcArrow();
  };

  let refInterval;
  useEffect(() => {
    GetBcScrollValue();
  });
  useEffect(() => {
    if (breadcrumbsArrow.current) {
      scrollToRightOnMobile();
    } else {
      refInterval = setInterval(() => {
        if (breadcrumbsArrow.current) {
          clearInterval(refInterval);
          scrollToRightOnMobile();
        }
      }, 300);
    }
  }, []);

  return (
    <StyledBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      showBcArrow={showBcArrow}
      className={classes.root}
      ref={breadcrumbsWrapper}
      iconBackgroundColor={iconBackgroundColor}>
      {isLargeDown && showBcArrow && (
        <Box className={classes.bcNav} onClick={() => handleBcArrow()}>
          <KeyboardArrowRight
            ref={breadcrumbsArrow}
            className={classes.bcArrow}
          />
        </Box>
      )}
      <List
        className={classes.bcLinksCon}
        ref={breadcrumbs}
        itemScope
        itemType="https://schema.org/BreadcrumbList">
        {data.links.map((item, index) => (
          <ListItem
            className={classes.bcListItem}
            key={`${item.text}`}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem">
            {item.path ? (
              <NavigatorLink
                className={classes.bcLink}
                id={item?.id}
                href={item.path}
                itemProp="item"
                itemScope
                onClick={item.onClick}
                itemType="https://schema.org/Thing"
                translate={item.translate}
                itemID={
                  item.path.url
                    ? `${item.path.url}${index}`
                    : `${item.path}${index}`
                }>
                <span
                  itemProp="name"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </NavigatorLink>
            ) : (
              <span
                className="BCLabel"
                itemProp="name"
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            )}
            <meta itemProp="position" content={index + 1} />
            {index + 1 !== data.links.length && (
              <span className={classes.bcSeparator}>\</span>
            )}
          </ListItem>
        ))}
        {data.breadCrumbsTitle && (
          <ListItem
            className={classes.bcListItem}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem">
            <Typography className={classes.bcText} variant="h3" itemProp="name">
              {data.breadCrumbsTitle}
              <meta itemProp="position" content={data.links.length + 1} />
            </Typography>
          </ListItem>
        )}
      </List>
    </StyledBox>
  );
};
export default Breadcrumbs;
