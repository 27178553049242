import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import {
  Box, Grid, Typography, Link, Button,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import PATHS from 'lib/navigation/paths';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';

import {
  StyledBox,
  StyledReviewsTitleContainer,
  StyledAddress,
  StyledImgBox,
} from 'views/locations-module/LocationView/components/LocationDetails/LocationDetails.styles';

const LocationMapWrapper = dynamic(() => import(
  'views/locations-module/LocationView/components/LocationDetails/LocationMapWrapper'
).then((mod) => mod.LocationMapWrapper));

const ContactUsDialog = dynamic(() => import(
  'views/common/components/Logical/Layout/Footer/NeedHelp/ContactUsDialog'
));

const LocationDetails = (props) => {
  const {
    dealer, location, phones, metrics,
  } = props;

  const { t } = useTranslation('fe_er_location_page');
  const appState = useSelector((state) => state.globalData.appState);
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);

  const handleOpenContactUsDialop = (value) => {
    setOpenContactUsDialog(value);
  };
  const [showMap, setShowMap] = useState(false);
  const handleShowMapClick = () => {
    setShowMap((prev) => !prev);
  };

  const adjustRating = (aggregateRating = 0) => (aggregateRating >= 1 ? parseFloat(aggregateRating).toFixed(1) : 0);

  return (
    <StyledBox className="LVTableBoxShadow">
      <Grid
        container
        rowSpacing={{ lg: 0, md: 4, xs: 4 }}
        columnSpacing={{ xs: 1, lg: 2 }}>
        <Grid item lg={7} md={12} xs={12}>
          {dealer?.image_url && (
            <Box className="LDlogoImg">
              <StyledImgBox
                image={{
                  src: dealer.image_url,
                  alt: dealer.name,
                }}
                imgWidth="100%"
                imgHeight="100%"
                imgObjectFit="contain"
              />
            </Box>
          )}
          <Box pt={2} pb={1} className="LDTitle">
            {dealer && (
              <Typography
                variant="body2"
                component="p"
                className="LDDispalyBlock">
                {t('fe_er_location_page:operating')}
              </Typography>
            )}

            <Typography500
              variant="h3"
              component="h2"
              className="LDDispalyBlock">
              {dealer
                ? `  ${dealer.name} ${t(
                  'fe_er_location_page:location_and_hours',
                )}`
                : `${t('fe_er_location_page:eaglerider')}
                 ${location.display_name}
                 ${t('fe_er_location_page:location_and_hours')}`}
            </Typography500>
          </Box>

          <StyledReviewsTitleContainer
            // title={t('fe_er_location_page:ratings')}
            rateAvg={adjustRating(metrics?.aggregate_rating)}
            reviewsCount={metrics?.count}
            reviewCountTitle={t('fe_er_location_page:reviews')}
            path={PATHS.locationReviews(location.slug)}
          />

          <Box className="LDdata">
            <NavigatorLink
              target="_blank"
              mapUrl={location.map_url}
              className="LDAddressLink">
              <StyledAddress
                location={dealer ? dealer.name : location.address1}
                isDealer={!!dealer}
                details={
                  dealer
                    ? `${location.address1}\n${
                      location.address2 && `${location.address2}\n`
                    }${location.full_address}`
                    : `${location.address2 && `${location.address2}\n`}${
                      location.full_address
                    }`
                }
              />
            </NavigatorLink>

            <Grid container item className="LDContentText" my={2}>
              <Grid item className="LDicon">
                <AccessTimeIcon />
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  component="p"
                  className="LDhourOperation">
                  {t('fe_er_location_page:hours_operation')}
                </Typography>
                <Box className="LDhourData">
                  {location.compressed_operation_hours.map((item) => (
                    <Typography variant="body2" component="p">
                      {`${item.days_range} ${t(
                        'fe_er_location_page:operation_time',
                        {
                          starting_hour: item.from,
                          ending_hour: item.to,
                        },
                      )}`}
                    </Typography>
                  ))}
                  {location.working_hours_comment && (
                    <Typography variant="h6" component="p">
                      <Typography500 variant="h6" component="span">
                        {t('fe_er_location_page:note')}
                      </Typography500>

                      <Typography
                        variant="h6"
                        component="span"
                        className="italicText">
                        {' '}
                        {location.working_hours_comment}
                      </Typography>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container item className="LDContentText" mb={1}>
              <Grid item>
                <CallIcon />
              </Grid>
              <Grid item>
                {phones.map((item) => (
                  <Box mb={1}>
                    <Link href={`tel:${item}`} className="LDphone LDphoneline">
                      {item}
                    </Link>
                  </Box>
                ))}
              </Grid>
            </Grid>

            <Grid container item className="LDContentText" mb={2}>
              <Grid item>
                <MailIcon />
              </Grid>
              <Grid item>
                <Button
                  className="LDEmailUs"
                  onClick={() => {
                    handleOpenContactUsDialop(true);
                  }}>
                  {t('fe_er_location_page:email_us')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={5} md={12} xs={12} className="LDMapGrid">
          <Box className="LDMapContiner">
            {showMap ? (
              <LocationMapWrapper location={location} />
            ) : (
              <Button
                onClick={handleShowMapClick}
                variant="outlined"
                color="secondary">
                {t('fe_er_common_circal_map:show_map')}
              </Button>
            )}
          </Box>

          <Typography
            variant="caption"
            component="p"
            className="LDMapDesc"
            dangerouslySetInnerHTML={{
              __html: location.directions_text,
            }}
          />
        </Grid>
      </Grid>
      {openContactUsDialog && (
        <ContactUsDialog
          open={openContactUsDialog}
          onClose={() => handleOpenContactUsDialop(false)}
          er
          title={t('fe_er_location_page:contact_eaglerider')}
          userId={appState.current_user?.id || null}
        />
      )}
    </StyledBox>
  );
};

export { LocationDetails };
