import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import ReviewsTitle from 'views/common/components/UI/ReviewsTitle/ReviewsTitle';
import { Address, ImgBox } from 'views/common/components';

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  '& .LDlogoImg': {
    height: '45px',
    width: 'auto',
  },
  '& .LDTitle': {
    color: theme.palette.secondary.dark,
  },
  '& .LDdata': {
    '& .LDContentText': {
      '&.MuiGrid-root': {
        flexWrap: 'nowrap',
      },
      '& svg': {
        fill: theme.palette.common.black,
      },
    },

    '& .LDphone': {
      fontWeight: 500,
      lineHeight: theme.spacing(2.625),
      '&.LDphoneline': {
        '&.MuiLink-root': {
          textDecoration: 'underline',
        },
      },
    },

    '& svg': {
      fontSize: theme.spacing(2),
      verticalAlign: 'text-bottom',
      fill: theme.palette.grey[300],
      marginRight: theme.spacing(1.6),
    },

    '& .LDhourOperation': {
      color: theme.palette.secondary.dark,
    },

    '& .LDhourData': {
      color: theme.palette.common.black,
      '& .italicText': {
        fontStyle: 'italic',
      },
    },
  },
  '& .LDEmailUs': {
    padding: 0,
  },
  '& .LDGray300': {
    color: theme.palette.grey[300],
  },
  '& .LDDispalyBlock': {
    display: 'block',
  },
  '& .LDMapGrid': {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    background: theme.palette.common.white,
    padding: '0px !important',
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(1),
    },
  },
  '& .LDMapContiner': {
    height: theme.spacing(56.25),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(46.5),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(42.5),
    },
  },
  '& .LDMapDesc': {
    padding: theme.spacing(1.25),
    color: theme.palette.grey[300],
  },
  '& .LDAddressLink': {
    display: 'inline-block',
  },
}));

export const StyledReviewsTitleContainer = styled(ReviewsTitle)(
  ({ theme }) => ({
    '& .ReviewsTitle-titleContainer': {
      marginBottom: theme.spacing(3),
      '& .MuiGrid-item': {
        paddingTop: theme.spacing(1),
        '& .ReviewsTitle-title': {
          '& .MuiTypography-h3': {
            color: theme.palette.secondary.dark,
            fontSize: theme.spacing(2.5),
            lineHeight: theme.spacing(4),
          },
        },
        '& .ReviewsTitle-dot': {
          display: 'none',
        },
        '& .ReviewsTitle-titleSecond': {
          color: theme.palette.secondary.dark,
        },
        '& .ReviewsTitle-reviewsCount': {
          fontWeight: 500,
          color: theme.palette.common.black,
          paddingLeft: theme.spacing(1),
          textDecoration: 'underline',
          textTransform: 'uppercase',
        },
      },
    },
  }),
);
export const StyledAddress = styled(Address)(({ theme }) => ({
  '&.bookingLocation': {
    '& .locationContiner': {
      maxWidth: 'unset !important',
    },
    '& svg': {
      fill: theme.palette.common.black,
    },
  },
}));
export const StyledImgBox = styled(ImgBox)(() => ({
  '& .IBimg': {
    objectPosition: 'left',
  },
}));
