import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '&.EARCSContiner': {
      '& >.containerRoot': {
        marginBottom: 0,
      },
      '& .EARCSMobile': {
        '& .MuiButton-root': {
          width: '100%',
          marginBottom: theme.spacing(3),
        },
      },
      '& .EARCSLarge': {
        display: 'none',
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    '& .EARCSMobile': {
      display: 'none',
    },
    '& .CarouselItem': {
      '& .EARCSCard': {
        height: theme.spacing(34.875),
        [theme.breakpoints.up('md')]: {
          height: '100%',
        },
        '& .rideContent': {
          '& .MuiGrid-container': {
            minHeight: theme.typography.pxToRem(58),
          },
        },
      },
      '& .EARCSLarge': {
        '& .MuiButton-root': {
          marginTop: theme.spacing(1.75),
        },
      },
    },
  },
}));

export default StyledBox;
