import React from 'react';
import { Grid, Typography } from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import Youtube from 'react-lazyload-youtube';
import 'react-lazyload-youtube/dist/index.css';
import StyledGrid from 'views/locations-module/LocationView/components/AboutLocationSection/AboutLocationSection.styles';

const AboutLocationSection = (props) => {
  const { youtubeId, title, desc } = props;

  return (
    <StyledGrid container spacing={2} className="LVTableBoxShadow">
      <Grid item xs={12} md={12} lg={youtubeId ? 6 : 12}>
        <Typography500
          variant="h3"
          component="p"
          className="ASTitle"
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <Typography
          component="p"
          variant="body1"
          className="ASDesc"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      </Grid>
      {youtubeId && (
        <Grid item xs={12} md={12} lg={6}>
          <Youtube videoId={youtubeId} width="100%" height="312px" />
        </Grid>
      )}
    </StyledGrid>
  );
};
export { AboutLocationSection };
